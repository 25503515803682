//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { ref, reactive, toRefs, watch, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import { uniqueId, debounce } from 'lodash';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

export default {
  name: 'Modal',
  props: {
    className: {
      type: String
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    shouldPersistInDom: {
      type: Boolean,
      default: false
    },
    transitionName: {
      type: String,
      default: 'fade-in-down'
    },
    shouldPortal: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const state = reactive({
      isContentElementOverflowingTop: false,
      isContentElementOverflowingBottom: false
    });

    const checkContentElementOverflow = (element) => {
      const hasOverflow = element.offsetHeight < element.scrollHeight;

      let hasOverflowAtTop = false;
      let hasOverflowAtBottom = false;

      if (hasOverflow) {
        hasOverflowAtTop = element.scrollTop > 10;
        hasOverflowAtBottom = element.scrollTop < element.scrollHeight - element.offsetHeight - 10;
      }

      state.isContentElementOverflowingTop = hasOverflowAtTop;
      state.isContentElementOverflowingBottom = hasOverflowAtBottom;
    };

    const contentElementId = ref('modalContent_' + uniqueId());
    let contentElementResizeObserver = null;

    const debouncedContentElementScrollEventHandler = debounce((event) => {
      checkContentElementOverflow(event.target);
    }, 200);

    onMounted(() => {
      contentElementResizeObserver = new ResizeObserverPolyfill((entries) => {
        // based on https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          entries.forEach((entry) => {
            const element = entry.target;
            checkContentElementOverflow(element);
          });
        });
      });
    });

    const addContentElementObservers = () => {
      const element = document.querySelector('#' + contentElementId.value);

      if (element) {
        contentElementResizeObserver.observe(element);
        element.addEventListener('scroll', debouncedContentElementScrollEventHandler, {
          passive: true
        });
      }
    };

    const removeContentElementObservers = () => {
      const element = document.querySelector('#' + contentElementId.value);

      if (element) {
        contentElementResizeObserver.disconnect();
        element.removeEventListener('scroll', debouncedContentElementScrollEventHandler);
      }
    };

    onUnmounted(() => {
      removeContentElementObservers();
    });

    watch(
      () => props.isVisible,
      async (newVal) => {
        if (!newVal) {
          removeContentElementObservers();
          return;
        }

        // https://portal-vue.linusb.org/guide/caveats.html#refs
        await Vue.nextTick();
        await Vue.nextTick();

        addContentElementObservers();
      },
      {
        inheritAttrs: false
      }
    );

    return {
      ...toRefs(state),
      contentElementId
    };
  }
};
