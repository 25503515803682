//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reactive, toRefs } from '@nuxtjs/composition-api';

/*
The ModalWrapper component

- includes some default styling for header, content area, and optional footer area
- accepts an array of steps and thus can handle single and multi-step modals

TODO: add custom validator to "steps" prop

*/

export default {
  components: {
    Modal: () => import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/modals/Modal'),
    CrossIcon: () => import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--cross.svg?inline')
  },
  props: {
    className: {
      type: String
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default: []
    },
    startAt: {
      type: Number,
      default: 0
    },
    showOnline: {
      type: Boolean,
      default: true
    },
    shouldPersistInDom: {
      type: Boolean,
      default: false
    },
    shouldPortal: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const state = reactive({
      currentStepIndex: props.startAt
    });

    function goToPreviousStep() {
      if (state.currentStepIndex === 0) {
        return;
      }

      state.currentStepIndex--;
    }

    function goToNextStep() {
      if (props.steps.length - 1 === state.currentStepIndex) {
        return;
      }

      state.currentStepIndex++;
    }

    // In order to simplify usage of this component, we would like to keep track of the currentStepIndex within this
    // component. Therefore there is no way for outer components to switch steps, i.e. increase / descrease this index.
    //
    // But the outer component provides all the steps, including primaryAction and secondaryAction for each step.
    // Therefore the outer component needs a way to specify click event handlers. There are two special event handler
    // names for now ("goToPreviousStep", "goToNextStep") that outer components can use within there steps definition
    // in order to tell this component to navigate between steps.
    //
    // This method here will be used to replace these string-based event handlers with their final implemenation.
    const replaceSpecialEventHandlers = (eventHandlerMap) => {
      for (const [key, value] of Object.entries(eventHandlerMap)) {
        switch (value) {
          case 'goToPreviousStep':
            eventHandlerMap[key] = goToPreviousStep;
            break;

          case 'goToNextStep':
            eventHandlerMap[key] = goToNextStep;
            break;
        }
      }

      return eventHandlerMap;
    };

    return { ...toRefs(state), goToPreviousStep, goToNextStep, replaceSpecialEventHandlers };
  }
};
