var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"app-modals","disabled":!_vm.shouldPortal}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isVisible || _vm.shouldPersistInDom)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"modal-backdrop",on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":_vm.transitionName}},[(_vm.isVisible || _vm.shouldPersistInDom)?_c('div',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],ref:"modal",class:['modal', _vm.className]},'div',_vm.$attrs,false),[_c('div',{ref:"modal-content",class:{
          'modal-container': true,
          'content-overflowing': _vm.isContentElementOverflowingTop || _vm.isContentElementOverflowingBottom,
          'content-overflowing-top': _vm.isContentElementOverflowingTop,
          'content-overflowing-bottom': _vm.isContentElementOverflowingBottom
        }},[(_vm.$slots.header)?_c('header',{staticClass:"modal-header weight-700"},[_vm._t("header"),_vm._v(" "),_vm._t("closeButton")],2):_vm._e(),_vm._v(" "),(_vm.$slots.content)?_c('div',{class:{
            'modal-content': true,
            overflowing: _vm.isContentElementOverflowingTop || _vm.isContentElementOverflowingBottom,
            'overflowing-top': _vm.isContentElementOverflowingTop,
            'overflowing-bottom': _vm.isContentElementOverflowingBottom
          },attrs:{"id":_vm.contentElementId}},[_vm._t("content")],2):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),_c('footer',{staticClass:"modal-footer"},[_vm._t("footer")],2)],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }