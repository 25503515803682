//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, useContext } from '@nuxtjs/composition-api';

export default {
  name: 'CityAndDateSelect',
  components: {
    ModalWrapper: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/modals/ModalWrapper')
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    startAt: {
      type: Number,
      default: 0
    },
    event: {
      type: Object,
      default: () => {}
    },
    city: {
      type: Object,
      default: () => {}
    },
    availableCities: {
      type: Array,
      default: () => []
    },
    availableDatesInCity: {
      type: Array,
      default: () => []
    },
    showOnline: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    isModalPrompt: {
      type: Boolean,
      default: false
    },
    shouldPersistInDom: {
      type: Boolean,
      default: false
    },
    shouldPortal: {
      type: Boolean,
      default: true
    },
    shouldHref: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { i18n } = useContext();
    const selectedCity = ref(props.city);

    const steps = computed(() => {
      if (props.city) {
        return [
          {
            title: i18n.t('category.search_title'),
            content: {
              component: () =>
                import(
                  /* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/CitySelector'
                ),
              props: {
                cities: props.availableCities,
                city: selectedCity.value,
                loading: false,
                showOnline: props.showOnline,
                showSearch: props.showSearch,
                isModalPrompt: props.isModalPrompt,
                shouldHref: props.shouldHref
              },
              events: {
                change: (cat) => {
                  if (cat.slug === 'online-events') {
                    emit('citySelected', cat, true);
                  } else {
                    emit('citySelected', cat, false);
                  }
                  selectedCity.value = cat;
                }
              }
            },
            primaryAction: {
              component: () =>
                import(
                  /* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'
                ),
              title: 'Datum wählen',
              props: {},
              events: {
                click: () => {
                  emit('citySelected', selectedCity.value, true);
                }
              }
            },
            secondaryAction: {
              component: 'a',
              title: 'Auswahl speichern',
              props: {
                role: 'button',
                href: '#'
              },
              events: {
                click: () => {
                  emit('citySelected', selectedCity.value);
                  emit('close');
                }
              }
            }
          },
          {
            title: 'Datum wählen',
            content: {
              component: () =>
                import(
                  /* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/DateSelector'
                ),
              props: {
                events: props.availableDatesInCity,
                city: props.city,
                loading: false
              }
            },
            primaryAction: {
              component: () =>
                import(
                  /* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'
                ),
              title: 'Speichern',
              props: {},
              events: {
                click: () => {
                  console.log('todo: save selection');
                }
              }
            },
            secondaryAction: {
              component: 'a',
              title: 'Zurück',
              props: {
                role: 'button',
                href: '#'
              },
              events: {
                click: 'goToPreviousStep'
              }
            }
          }
        ];
      }

      return [
        {
          title: i18n.t('category.search_title'),
          content: {
            component: () =>
              import(
                /* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/CitySelector'
              ),
            props: {
              cities: props.availableCities,
              city: selectedCity.value,
              loading: false,
              showOnline: props.showOnline,
              showSearch: props.showSearch,
              isModalPrompt: props.isModalPrompt,
              shouldHref: props.shouldHref
            },
            events: {
              change: (cat) => {
                if (cat.slug === 'online-events') {
                  emit('citySelected', cat, true);
                } else {
                  emit('citySelected', cat, false);
                }
                selectedCity.value = cat;
              }
            }
          }
        },
        {
          title: 'Datum wählen',
          content: {
            component: () =>
              import(
                /* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/DateSelector'
              ),
            props: {
              events: props.availableDatesInCity,
              city: props.city,
              loading: false
            }
          }
        }
      ];
    });

    return {
      steps
    };
  }
};
